.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px var(--padding);
    position: fixed;
    top: 0; 
    left: 0; 
    right: 0; 
    z-index: 20;
    background: #FFF;
    box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.05);
}
  
.logo {
    color: #000;
    font-family: var(--font), sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 20px;
}
  
.nav-links ul {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
    gap: 50px;
    align-items: center;
}
  
.nav-links li {
    transition: transform 0.2s ease, font-variation-settings 0.3s;
}
 
.text-link:hover,
.text-link:active {
    text-shadow: 1px 0 black;
    transform: scale(1.5);
    cursor: pointer;
}

.nav-links a.active,
.nav-links a:hover {
    text-shadow: 1px 0 black, -0.5px 0 black;
    transform: scale(1.5);
    text-align: center;
} 
  
.nav-links a {
    letter-spacing: 0.5px;
    text-decoration: none;
    color: #000;
    font-family: var(--font), sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 450;
    line-height: 20px;
}

.menu-icon {
    display: none;
    cursor: pointer;
    z-index: 6;
    transition: transform 0.3s ease;
    right: var(--padding);
}
  
.menu-icon.open {
    transform: rotate(90deg); 
    position: fixed; 
    right: var(--padding);
}
  
.nav-links {
    display: flex;
    flex-direction: row;
}
  
.nav-links.open {
    position: fixed; 
    top: 0;
    left: 0;
    right: 0;
    background: rgba(244, 249, 253, 0.9);
    backdrop-filter: blur(10px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    z-index: 5;
    transition: transform 0.3s ease-in-out;
    overflow: auto; 
}
  
.nav-links.open ul {
    display: flex;
    flex-direction: column;
    align-items: center;
}
  
.nav-links.open li {
    margin: 20px 0;
}

label {
    display: none
}

.logo-link {
     text-decoration: none;
}
  
@media (max-width: 768px) {
    .nav-links {
        display: none;
    }
  
    .menu-icon {
        display: block;
    }

    body.menu-open {
        overflow: hidden;
    }

    .menu-icon {
        display: block;
    }
    
    label {
        display: flex;
        flex-direction: column;
        width: 25px; 
        height: auto;
        cursor: pointer;
        z-index: 30;
     }
    
    label span {
        background: black;
        border-radius: 10px;
        height: 3px; 
        margin: 2.5px 0; 
        transition: 0.4s cubic-bezier(0.68, -0.6, 0.32, 1.6);
    }
    
    span:nth-of-type(1) {
        width: 50%;
    }
    
    span:nth-of-type(2) {
        width: 100%;
    }
    
    span:nth-of-type(3) {
        width: 75%;
    }

    input[type="checkbox"]{
        display:none;
    }
      
      
    input[type="checkbox"]:checked ~ span:nth-of-type(1){
        transform-origin:bottom;
        transform:rotatez(45deg) translate(3.5px,0px)
    }
      
      
    input[type="checkbox"]:checked ~ span:nth-of-type(2){
        
        transform-origin:top;
        transform:rotatez(-45deg)
    }
      
      
    input[type="checkbox"]:checked ~ span:nth-of-type(3){
        
        transform-origin:bottom;
        width:50%;
        transform: translate(12px,-4.5px) rotatez(45deg);
    }

}
