.scroll-container-wrapper > :not(.scroll-container) {
    padding: 20px var(--padding);
}

.scroll-container-wrapper {
    position: relative;
    width: 100%;
    margin-bottom: 8%;
    margin-top: 3%;
}

.scroll-container.grabbing {
    cursor: grabbing;
}

.scroll-container {
    position: absolute;
    right: 0;
    left: 0;
    display: flex;
    overflow-x: auto;
    cursor: grab;
    white-space: nowrap;
    width: 100%;
    position: relative;
    scrollbar-width: none;
    -ms-overflow-style: none;
    scroll-behavior: smooth;
}

.scroll-container::-webkit-scrollbar {
    display: none; 
}

.scroll-container .scroll-content {
    display: flex;
    gap: 40px;
    color: #000;
    font-family: "Poppins", sans-serif;
    font-size: 50px;
    font-weight: 700;
    line-height: normal;
    user-select: none;
    position: relative;
    z-index: 1;
    margin: 0 auto; 
}

.scroll-container-wrapper .background-text {
    position: absolute;
    margin-top: 25px;
    transform: translateY(-50%);
    color: rgba(0, 0, 0, 0.1); 
    white-space: nowrap;
    pointer-events: none;
    z-index: -1; 
    color: #F4F9FD;
    font-family: "Poppins", sans-serif;
    font-size: 80px;
    font-style: normal;
    font-weight: 900;
    line-height: 100px;
}

.filter-buttons {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
}

.filter-button {
    padding: 5px 15px;
    border: none;
    cursor: pointer;
    color: #000;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 25px; 
    background-color: transparent;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 40px; 
    transition: all 0.2s ease; 
    letter-spacing: 0.3px;
}


.filter-button:hover {
    text-shadow: 1px 0 black, -0.5px 0 black;
    transform: scale(1.3); 
    cursor: pointer;
}

.filter-button.active {
    text-shadow: 1px 0 black, -0.5px 0 black;
    cursor: pointer;
}

.cont {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.scroll-arrows {
    z-index: 2;
    display: flex;
    gap: 20px;
    justify-content: flex-start;
}

.arrow-left,
.arrow-right {
    background: transparent;
    border: none;
    color: #000;
    cursor: pointer;
    transition: all 0.2s ease;
}

.arrow-left:hover,
.arrow-right:hover {
    transform: scale(2);
}

@media (max-width: 768px) {
    
    .cont {
        flex-direction: column;
    }

    .filter-buttons {
        display: flex;
        gap: 10px;
        justify-content: center;
        order: 1;
    }

    .scroll-arrows {
        order: 2;
        margin-top: 20px;
    }

    .scroll-container .scroll-content {
        font-size: 40px;
    }

    .scroll-container-wrapper {
        margin-top: 15%;
        margin-bottom: 10%;
    }

    .filter-button {
        font-size: 12px;
    }

    .arrow-left,
    .arrow-right {
        font-size: 20px;
    }
}
