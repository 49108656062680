.project-nav {
    border-radius: 20px;
    background: #FFF;
    box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.05);
    height: 690px;
    position: sticky;
    top: 120px;
    z-index: 10;
    overflow: hidden;
}

.pr-content {
    padding: 30px 20px;
}
  
.project-list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    gap: 1rem;
    flex-direction: column;
  }
  
  .project-list li {
    margin: 0;
  }

  .navigation ul {
    list-style: none;
    padding: 10px;
    /* margin: 20px; */
    display: flex;
    gap: 2px;
    flex-direction: column;
    align-content: center;
  }
  
  .project-list a {
    text-decoration: none; 
    color: black; 
    font-weight: bold;
    text-align: center;
    padding: 10px 0;
    font-size: 16px;
    cursor: pointer;
    transition: box-shadow 0.3s ease, font-weight 0.3s ease, background-color 0.3s ease;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 25px;
    border-radius: 20px;
    width: 100%;
  }

  .a-link {
    padding: 5px 10px;
    font-size: 12px;
    border: none;
    cursor: pointer;
    transition: box-shadow 0.3s ease, font-weight 0.3s ease;
    color: #000;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 600;
    width: 100%;
    background-color: transparent;
    text-align: left;
  }
  
  .a-link.highlight {
    font-weight: 900;
    background-color: #F4F9FD;
    border-radius: 20px; 
  }

  .a-link:hover {
    font-weight: 900;
    background-color: #F6F6F6;
    border-radius: 20px; 
  }


  @media (max-width: 768px) {
    .navigation {
      display: none; 
    }
  
    .project-list {
      flex-direction: row; 
      width: 100%;
      justify-content: center;
    }
  
    .project-button {
      width: 100%; 
    }

    .project-nav {
        height: 100px;
        position: fixed;
        bottom: 0;
        top: auto;
        left: 5%;
        right: 5%;
        border-radius: 20px 20px 0 0;
    }

    .pr-content {
        padding: 20px 20px;
    }
}