.project-widget-wrapper {
    width: 100%;
    border-radius: 20px;
    background: #FFF;
    box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.05);
    padding: 10% 10% 13% 10%;
    display: flex;
    justify-content: space-between; 
    align-items: center;
    overflow: hidden;
    cursor: pointer;
}

.project-widget-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between; 
    align-items: center;
    transition: all 15s ease;
}

.project-widget-container .text-container {
    display: flex;
    flex-direction: column;
    color: #000;
    text-align: left;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    flex: 1 1 auto; 
    transition: transform 1s ease, margin-top 0.3s ease;
    z-index: 1;
}

.project-widget-wrapper:hover .text-container {
    transform: translateX(5%);
}

.project-widget-wrapper:not(:hover) .text-container {
    transform: translateX(0);
}

.project-widget-container .text-container h1 {
    font-size: 25px;
    font-weight: 800;
    line-height: normal;
}

.project-widget-container .text-container p {
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    margin-top: -5px;
    transition: margin-top 0.3s ease;
}

.project-widget-container .image-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;  
    transition: transform 1s ease, margin-top 0.3s ease;
    z-index: 0;
}

.project-widget-wrapper:hover .image-container {
    transform: translateX(-10%);
}

.project-widget-wrapper:not(:hover) .image-container {
    transform: translateX(0);
}

.learn-more-label {
    position: absolute;
    margin-left: 1px;
    margin-top: 0; 
    font-weight: 500;
    opacity: 0;  
    visibility: hidden;  
    transition: opacity 3s ease; 
    color: #3D8EC3;
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 12px; 
    letter-spacing: 0.3px;
    text-transform: capitalize;
    transform: translateX(10%);
}

.project-widget-wrapper:hover .learn-more-label {
    opacity: 1;  
    visibility: visible;  
}

.project-widget-wrapper:not(:hover) .learn-more-label {
    visibility: hidden;  
}

@media (max-width: 768px) {
    .project-widget-container .text-container h1 {
        font-size: 18px;
        font-weight: 900;
        line-height: normal;
    }

    .project-widget-container .text-container p {
        font-size: 12px;
        font-weight: 500;
        line-height: 15px;
        margin-top: -5px;
        transition: margin-top 0.3s ease;
    }

    .project-widget-container .image-container {
        width: 80px;
        height: 80px;
    }

    .project-widget-wrapper:hover .text-container {
        transform: translateX(0);
    }
    
    .project-widget-wrapper:not(:hover) .text-container {
        transform: translateX(0);
    }

    .project-widget-wrapper:hover .image-container {
        transform: translateX(0);
    }
    
    .project-widget-wrapper:not(:hover) .image-container {
        transform: translateX(0);
    }

    .project-widget-wrapper .learn-more-label {
        opacity: 1;  
        visibility: visible;
        transform: translateX(0);
        margin-left: 0;
    }
    
    .project-widget-wrapper:not(:hover) .learn-more-label {
        visibility: visible;  
    }

}