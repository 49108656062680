.btn {
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    transition: box-shadow 0.3s ease, text-shadow 0.3s ease;
    color: #000;
    font-family: var(--font), sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 25px;
    border-radius: 20px;
    width: 100%;
    letter-spacing: 0.1px;
}

.btn:hover,
.btn:active {
    text-shadow: 1px 0 black;
    box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.btn.active {
    text-shadow: 1px 0 black;
    box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.btn.with-avatar {
    padding: 10px 10px;
}

.primary-button {
    background-color: #F4F9FD;
    color: black;
}

.grey-button {
    background-color: #F6F6F6;
    color: black;
}

.project-button {
    background-color: #F4F9FD;
    color: black;
    text-align: left;
}

.header-button {
    background-color: #F4F9FD;
    color: black;
}

.button-text {
    display: inline; 
}
  
.button-avatar {
    display: none;
}

@media (max-width: 768px) {
    
    .header-button {
        background: black;
        color: white;
        padding: 10px 30px;
    }

    .button-avatar {
        display: block;
        width: 45px; 
        height: 45px; 
    }

    .button-text.with-avatar {
        display: none; 
    }
    
    .button-text {
        display: inline; 
    }
      
}