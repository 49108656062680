.layout-container {
    display: flex;
    position: relative;
    gap: 20px;
    margin-top: 120px;
    padding-left: var(--padding);
    padding-right: var(--padding);
    margin-bottom: 120px;
}
  
.project-details {
    flex: 1;
    background-color: #ffffff;
    width: 75%;
}

.project-main {
    width: 25%;
}

@media (max-width: 768px) {
    
    .layout-container {
        flex-direction: column;
    }

    .project-details {
        width: 100%;
    }

    .project-main {
        width: 100%;
    }

}
