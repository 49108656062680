@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;1,400&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
  --width: 1150px;
  --padding: calc(calc(110% - var(--width))/2);
  --font: "Poppins"; 
}

@media (max-width: 768px) {

  :root {
    --width: 350px;
  }

}