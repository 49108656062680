.main-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;  
    align-items: center;
    padding: 20px var(--padding);
}

.about-section-overlap {
    margin-top: 10%;
    margin-bottom: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
}
  
.about-section-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    position: relative;
    gap: 8%;
}

.about-section-container .text-container {
    color: #000;
    font-family: var(--font), sans-serif;
    font-style: normal;
    line-height: normal;
    line-height: 20px;
}
  
.about-section-container .text-container h1 {
    font-size: 30px;
    font-weight: 900;
    margin-bottom: 45px;
}

.about-section-container .text-container span {
    font-weight: 700;
}

.about-section-container .text-container p {
    font-size: 14px;
}

.button-container {
    margin-top: 10%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

@media (max-width: 768px) {
    
    .about-section-container {
        flex-direction: column; 
        width: 90%; 
    }

    .about-section-container .text-container {
        text-align: justify;
        order: 2;
    }

    .about-section-container .image-section {
        display: none;
    }

    .about-section-container .button-container {
        flex-direction: column;
        margin-top: 15%;
        order: 3;
    }

}