.project-page-template-container {
    display: flex;
    flex-direction: column;
    justify-content: center;  
    color: #000;
    font-family: var(--font), sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
  
.rectangle {
    width: 100%;
    height: 120px; 
    background-color: #F4F9FD;
    border-radius: 20px; 
    justify-content: start;
}

.bold-italic {
    font-style: italic;
    font-weight: 600;
}
  
.circle {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 5%;
    margin-top: 80px;
    width: 100px; 
    height: 100px;
    background-color: white;
    border-radius: 50%;
}
  
.circle img {
    width: 70%; 
    height: 70%;
}
  
.content {
    margin-top: 30px; 
    padding: 5%;
}
  
.content h1 {
    font-size: 30px;
    font-weight: 900;
    margin-top: 20px;
    font-weight: 900;
}

.content h2 {
    font-size: 20px;
    font-weight: 900;
    margin-top: 80px;
    font-weight: 900;
}

.content h3 {
    font-size: 18px;
    font-weight: 700;
    margin-top: 50px;
}

.content h4 {
    margin-top: 20px;
    font-size: 16px;
}

.content h5 {
    font-size: 14px;
    font-weight: 600;
    margin-top: 50px;
}
  
.content p {
    font-size: 14px;
    text-align: justify;
}

.content img {
    width: 100%;
    margin-top: 20px;
}

.content .list-style {
    list-style-type: disc; 
    margin-left: 50px;
    display: list-item;
}

.content .list-style-2 {
    list-style-type: disc; 
    margin-left: 100px;
    display: list-item;
}

.content Button {
    margin-top: 20px;
    width: 30%;
}

@media (max-width: 768px) { 
    .content Button {
        width: 100%;
    }
}

.code-link-container {
    display: flex;
    align-items: center; 
    justify-content: end;
    margin-top: 50px;
}

.code-link {
    display: flex;
    align-items: center; 
    gap: 5px; 
}

.code-link {
    font-size: 14px;
    text-decoration: none;
    font-weight: 700;
    color: #3D8EC3;
    float: right;
    transition: 0.3s ease color;
}

.code-link:active,
.code-link:hover {
    text-decoration: none;
    color: black;
    float: right;
}

.code-link svg path {
    fill: #3D8EC3;
    transition: 0.3s ease fill;
}

.code-link:hover svg path {
    fill: black; 
}

table {
    width: 100%;
    border-collapse: collapse;
    font-size: 12px;
}

th, td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

th {
    background-color: #F4F9FD;
}

tr:nth-child(even) {
    background-color: #f9f9f9;
}

tr:hover {
    background-color: #f1f1f1;
}

.code-block {
    font-size: 10px;
}

pre {
    background: #f4f4f4;
    border: 1px solid #ddd;
    color: #666;
    page-break-inside: avoid;
    font-family: monospace;
    font-size: 12px;
    line-height: 1.6;
    margin-bottom: 1.6em;
    max-width: 100%;
    overflow: auto;
    display: block;
    word-wrap: break-word;
}

.script {
    padding: 1em 1.5em;
}

.keyword {
    background-color: #f0f0f0; 
    padding: 5px 10px;
    border-radius: 15px; 
    font-weight: bold; 
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 10px;
}
