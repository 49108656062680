.footer-container {
    display: flex;
    flex-direction: column;
    justify-content: center;  
    align-items: center;
    text-align: center;
    padding: 10px var(--padding);
    background-color: white;
}

.footer-content {
    color: #000;
    font-family: var(--font), sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    text-align: center;
    display: flex; 
    flex-wrap: wrap;
}

.footer-content span::after {
    content: "•";
    margin: 0 10px; 
    color: #000; 
}

.footer-content span:last-child::after {
    content: ""; 
}

@media (max-width: 768px) {

    .footer-content {
        display: flex;
        justify-content: center;
        text-align: center;
        gap: 2px;
    }

    .footer-content span::after {
        content: ""; 
        margin: 0 0; 
    }
}
