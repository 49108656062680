.main-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;  
    align-items: center;
    padding: 20px var(--padding);
}

.intro-section-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 75%;
    position: relative;
}

.intro-section-overlap {
    margin-top: 30%;
    margin-bottom: 5%;
    display: flex;
    align-items: center;
    justify-content: center;
}
  
.intro-section-container:after {
    content: '';
    position: absolute;
    width: 70%;
    height: 80%;
    border-radius: 30px;
    background: #F4F9FD;
    z-index: -1;
    right: 8%; 
    bottom: 3.5px;
}

.intro-section-container .text-container {
    color: #000;
    font-family: var(--font), sans-serif;
    font-style: normal;
    line-height: normal;
    line-height: 20px;
}
  
.intro-section-container .text-container h1 {
    font-size: 40px;
    line-height: 50px;
    font-weight: 700;
}

.intro-section-container .text-container span {
    font-size: 40px;
    font-weight: 900;
}

.intro-section-container .text-container p {
    font-size: 14px;
    margin-top: -10px;
    line-height: 20px;
}

@media (max-width: 768px) {
   
    .intro-section-container {
        flex-direction: column; 
        width: 90%; 
    }

    .intro-section-container:after {
        width: 80%;
        height: 80%;
    }

    .intro-section-container .text-container p {
        font-size: 12px;
    }

    .intro-section-container .text-container {
        margin-bottom: 10px; 
    }
}