.grid-container {
    margin-top: 10px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

.grid-item {
    display: flex;
    justify-content: center; 
    align-items: center;
    width: 100%;
    height: 100%;
}

.ps-main-wrapper {
    background-color: #F4F9FD;
    padding: 100px var(--padding) 150px; 
    display: flex;
    flex-direction: column;
    justify-content: center;  
}

.ps-header {
    color: #000;
    font-family: "Poppins", sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: left;
    left: 0;
    margin-bottom: 50px;
}

.ps-main-wrapper span {
    font-weight: 900;
}

  
@media (max-width: 768px) {
    
    .grid-container {
        flex-direction: column;
    }

}